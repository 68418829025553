import { BedCategory } from '~/types/AcceptablePatientInfoTypes';

// 病棟種別を選択するボタン label の値を一致させること!!
export const ASSET_TYPE = {
  houkan: '訪看',
  hospital: '病院',
  clinic: '訪診',
  area: '包括',
  rouken: '老健',
  kakarituke: '近医',
  tokuyou: '特養',
  kaigoiryouin: '医療院'
} as const;

export type AssetType = (typeof ASSET_TYPE)[keyof typeof ASSET_TYPE];

// 印刷画面での表示用で使用している
export const ASSET_LABEL = {
  訪看: '訪問看護ステーション',
  病院: '病院',
  訪診: '訪問診療を行っている施設',
  老健: '介護老人保健施設',
  特養: '特別養護老人ホーム',
  近医: 'かかりつけ医',
  医療院: '介護医療院'
};

/**
 * ラベル掲載管理用定数
 * 施設元データに掲載されている各詳細項目が"あり"の場合は、ラベル表示する
 * 施設元デーが変更された場合は適宜変更、追加すること
 */
export const LABEL_SHOW_DISPLAY_WORD = ['あり'];

/**
 * コネクトで利用している対象病棟種類
 * コネクトのBUILD_TYPEと関連しているので変更するときは注意する（コネクトに合わせること）
 */
export const BUILD_TYPE_MAP = {
  ICU: 'ICU', //"HCU/ICU",
  IPPAN: 'IPPAN', //"一般病棟",
  RIHA: 'RIHA', //"回復期リハ病棟",
  AREA: 'AREA', //地域包括ケア病棟",
  AREA_MEDICAL: 'AREA_MEDICAL', //地域包括医療病棟"
  IRYOU: 'IRYOU', //"医療療養病棟",
  KANWA: 'KANWA', //"緩和ケア病棟",
  SEISIN: 'SEISIN', //"精神科病棟"
  SHOUGAI: 'SHOUGAI', // "障がい者病棟",
  ROUKEN: 'ROUKEN', // "介護老人保健施設",
  YUURYOU: 'YUURYOU', // "有料老人ホーム・サ高住",
  HOUKAN: 'HOUKAN' // "訪問診療・訪問看護"
};

/**
 * コネクト受け入れ病棟データとのラベリング用
 * コネクトに合わせること
 */
export const BUILD_TYPE = {
  ICU: 'HCU/ICU',
  IPPAN: '一般病棟',
  RIHA: '回復期リハ病棟',
  AREA: '地域包括ケア病棟',
  AREA_MEDICAL: '地域包括医療病棟',
  IRYOU: '医療療養病棟',
  KANWA: '緩和ケア病棟',
  SEISIN: '精神科病棟',
  SHOUGAI: '障がい者病棟',
  ROUKEN: '介護老人保健施設',
  YUURYOU: '有料老人ホーム・サ高住',
  KAIGO_AGENT: '介護施設紹介センター',
  HOUKAN: '訪問診療・訪問看護'
};

/**
 * 空床情報データ(右側リストのラベル)とのラベリング用
 * コネクトで追加した病棟種別がある場合は、ここにも追加する
 */
export const BED_TYPES = {
  一般: 'ippan',
  感染: 'kansen',
  精神: 'seisin',
  結核: 'kekkaku',
  介護療養: 'kaigo',
  医療療養: 'iryou',
  緩和ケア: 'kanwa',
  認知症: 'ninchi',
  回復期リハ: 'riha',
  地域包括ケア: 'area',
  地域包括医療: 'areaMedical',
  透析: 'touseki'
};

/**
 * 空床情報ダイアログでの表示用ラベリング
 * [重要] この並びでTABLE表示される
 * コネクトで追加した病棟種別がある場合は、ここにも追加する
 */
export const BED_DETAIL_ASSET_TYPE = {
  ippan: '一般',
  riha: '回復期リハ',
  area: '地域包括ケア',
  areaMedical: '地域包括医療',
  iryou: '医療療養',
  kanwa: '緩和ケア',
  seisin: '精神科',
  kaigo: '介護療養',
  handicap: '障がい者'
  // 以下、初期リリースでは不要の病棟種別
  // kansen: '感染病床',
  // kekkaku: '結核病床',
  // ninchi: '認知症病棟',
  // touseki: '透析'
};

export const BED_CATEGORY_LIST: BedCategory[] = [
  'ippan',
  'riha',
  'area',
  'areaMedical',
  'iryou',
  'kanwa',
  'seisin',
  'kaigo',
  'handicap'
];

// 病院・施設毎のコネクトの利用モードを定義
export const CONNECT_MODE_MAP = {
  SENDER: 'sender', // 打診可能
  RECEIVER: 'receiver', // 回答可能
  BOTH: 'both' // 両面可能
};
