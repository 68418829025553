
import { mapGetters } from 'vuex';
export default {
  name: 'LoginRequestDialog',
  data() {
    return {
      showDialog: true
    };
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.closeDialog();
      }
    }
  },
  computed: {
    ...mapGetters({
      analyticsUserId: 'analyticsUserId',
      loginDialogVisible: 'loginDialogVisible'
    }),
    getEventLabel() {
      return this.analyticsUserId ? `問合せ, ${this.analyticsUserId}` : '問合せ';
    }
  },
  methods: {
    closeDialog: function () {
      this.showDialog = false;
      // 自分のノードを消さないと、ダイアログのノードが際限なく増え続ける
      // なので閉じるタイミングで自分のノードを削除する必要がある
      const element = document.getElementById('login-request-dialog');
      element.parentNode.removeChild(element);
    },
    openLogin: function () {
      this.showDialog = false;
      this.$analytics({
        // send Google Analytics
        eventCategory: '問い合わせフォーム導線',
        eventAction: 'ログイン導線ボタン押下',
        eventLabel: this.getEventLabel
      });
      document.location.href = '/login';
    }
  },
  mounted() {
    this.$analytics({
      // send Google Analytics
      eventCategory: '問い合わせフォーム導線',
      eventAction: 'ダイアログを表示',
      eventLabel: this.getEventLabel
    });
  }
};
